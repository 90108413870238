<template>
  <b-select
    v-model="selection"
    :loading="loading"
    :required="required"
    :disabled="disabled"
    :expanded="expanded"
    :placeholder="loading ? `Loading tasks` : `Select a task`"
    @input="$emit('selected', selection)"
  >
    <optgroup
      v-for="(category, categoryId) in categories"
      :key="categoryId"
      :label="category"
    >
      <option
        v-for="(t, taskId) in $_(taskTypes)
          .filter(tT => tT.category === category)
          .sortBy(tT => tT.name.toLowerCase())
          .value()"
        :key="taskId"
        :value="t"
        :disabled="t.isDisabled"
        >{{ t.name }}{{ t.isDisabled ? " (Premium)" : "" }}</option
      >
    </optgroup>
  </b-select>
</template>

<script>
import { collection, getDocs, query, where } from "@firebase/firestore";
export default {
  name: "TaskSelect",
  props: {
    software: {
      type: String,
      required: false,
      default: null
    },
    task: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false
    },
    hasCredits: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      selection: this.task,
      taskTypes: {}
    };
  },
  computed: {
    isAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    categories() {
      return this.$_(this.taskTypes)
        .map(task => task.category)
        .uniq()
        .sortBy(category => category.toLowerCase())
        .value();
    }
  },
  watch: {
    software() {
      this.selection = null;
      this.getTaskTypes();
    }
  },
  created() {
    this.getTaskTypes();
  },
  methods: {
    async getTaskTypes() {
      this.loading = true;
      let softwares = this.$_(["any", "other"])
        .union([this.software])
        .pull(null)
        .value();
      await getDocs(
        this.$store.getters["user/isAgent"]()
          ? query(
              collection(this.$firestore, "taskTypes"),
              where("software", "array-contains-any", softwares)
            )
          : query(
              collection(this.$firestore, "taskTypes"),
              where("isVisible", "==", true),
              where("software", "array-contains-any", softwares)
            )
      ).then(taskTypes => {
        taskTypes.forEach(doc => {
          this.$set(
            this.taskTypes,
            doc.id,
            this.$_.merge({}, doc.data(), {
              ref: doc.ref,
              isDisabled: !this.hasCredits && doc.data().creditCost > 0
            })
          );
        });
      });
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

option[disabled] {
  color: hsl(0, 0%, 71%);
}
</style>
